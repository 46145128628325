import { createApp } from "vue";
// import * as VueRouter from "vue-router";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { consts } from "@/app.config";

// const app = createApp(App);
//
// const Home = app.component("App");
// const About = { template: "<div>About</div>" };

// // 2. Define some routes
// // Each route should map to a component.
// // We'll talk about nested routes later.
// const routes = [
//   { path: "/", component: Home || About },
//   { path: "/about", component: About },
//   { path: "/:pathMatch(.*)*", component: About },
// ];

// const router = VueRouter.createRouter({
//   history: VueRouter.createWebHistory(),
//   routes,
// });

const app = createApp(App).use(router).use(store);
app.config.globalProperties.$consts = consts;
app.mount("#app");
