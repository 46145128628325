const deepFreeze = (obj: Record<string, unknown>) => {
  Object.keys(obj).forEach((prop) => {
    if (typeof obj[prop] === "object") {
      deepFreeze(obj[prop] as Record<string, unknown>);
    }
  });
  return Object.freeze(obj);
};

export const consts = deepFreeze({
  aaa: "!!!",
  red: "red",
  green: "green",
  blue: "blue",
  get colors() {
    return [this.red, this.green, this.blue];
  },
});
