
import { defineComponent } from "vue";
import { SET_VOLUME } from "@/store";

export default defineComponent({
  data() {
    return {
      volumeLevel: this.$store.state.globalVolume,
      volumeMuted: 0,
    };
  },
  methods: {
    muteToggle() {
      const previousVolume = this.volumeLevel;
      this.setVolume(this.volumeMuted);
      this.volumeMuted = previousVolume;
    },
    setVolume(volume: number) {
      if (volume > 0) {
        this.volumeMuted = 0;
      }
      this.volumeLevel = volume;
      this.$store.commit({ type: SET_VOLUME, volume: this.volumeLevel });
    },
    volumeChange(e: Event) {
      const target = e.target as HTMLInputElement;
      this.setVolume(Number(target.value));
    },
  },
});
