import { createStore } from "vuex";
import { images } from "@/store/db";

type ImageRecord = { [k: string]: string };
type ImagesStore = { [c: string]: ImageRecord };

export const SET_VOLUME = "setVolume";
const SET_IMAGES = "setImages";
const MARK_IMAGES_LOADING = "setImagesLoading";
export const LOAD_IMAGES = "loadImages";

export default createStore({
  state() {
    const images: ImagesStore = {};
    const storedVolume = localStorage.getItem("globalVolume");
    const defaultVolume = storedVolume
      ? Math.max(0, Math.min(Number(storedVolume), 1))
      : 0.5;
    return { images, loadingImages: false, globalVolume: defaultVolume };
  },
  getters: {},
  mutations: {
    [SET_VOLUME](state, payload: { volume: number }) {
      state.globalVolume = payload.volume;
      localStorage.setItem("globalVolume", payload.volume.toString());
    },
    [SET_IMAGES](state, payload: { images: ImagesStore }) {
      state.images = payload.images;
      state.loadingImages = false;
    },
    [MARK_IMAGES_LOADING](state) {
      state.loadingImages = true;
    },
  },
  actions: {
    [LOAD_IMAGES](context) {
      context.commit({ type: SET_IMAGES, images });
      // Pretend that this is an api request.
      /*
      context.commit({ type: MARK_IMAGES_LOADING });
      setTimeout(() => context.commit({ type: SET_IMAGES, images }), 1000);
      */
    },
  },
  modules: {},
});
