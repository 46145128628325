export const images: { [c: string]: { [k: string]: string } } = {
  Frodo: {
    "Frodo Headshot Smile": "/images/Frodo/headshot_smile.jpg",
    "Frodo Headshot Smug": "/images/Frodo/headshot_smug.jpg",
    "Frodo Paws Flat": "/images/Frodo/paws_flat.jpg",
    "Frodo Paws Smooth": "/images/Frodo/paws_smooth.jpg",
    "Frodo Paws Detailed": "/images/Frodo/paws_detailed.jpg",
  },
  Pencia: {
    "Pencia Headshot Blep": "/images/Pencia/headshot_blep.jpg",
    "Pencia Paws Smooth": "/images/Pencia/paws_smooth.jpg",
    "Pencia Paws Focus": "/images/Pencia/paws_focus.jpg",
    "Pencia Paws Lick": "/images/Pencia/paws_lick.jpg",
    "Pencia Paws Safety": "/images/Pencia/paws_safety.jpg",
  },
  Fenme: {
    "Fenme Fen Chill": "/images/Fenme/fen_vtuber.jpg",
    "Fenme Hug Offer": "/images/Fenme/hug_offer.jpg",
  },
  MonsterCheetah: {
    "MonsterCheetah Bust": "/images/MonsterCheetah/bust.jpg",
    "MonsterCheetah Snow": "/images/MonsterCheetah/snow.jpg",
    "MonsterCheetah Meme Outfit": "/images/MonsterCheetah/meme_outfit.jpg",
  },
};
